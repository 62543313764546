<template>
    <div class="login-body">
        <div class="login-box showed">
            <div class="login-form">
                <img src="@/assets/images/KIESC_logo_no_bg.png" width="400" height="100px" />
                <!-- <h3 class="pt-4 mb-2">{{title}}</h3> -->
                <v-alert
                    dense
                    color="red"
                    icon="mdi mdi-alert"
                    shaped
                    text
                    type="danger"
                    v-if="alert"
                    class="font-bold"
                >{{ message }}</v-alert>
                <v-form v-model="valid" ref="formdata" class="pt-2" @submit.prevent="submit">
                    <v-text-field 
                        type="text" 
                        label="ຊື່ຜູ້ໃຊ້ລະບົບ" 
                        placeholder="ຊື່ຜູ້ໃຊ້ລະບົບ" 
                        outlined  
                        dense
                        v-model="formLogin.username" 
                        :rules="formLogin.usernameRules" 
                        required 
                        append-icon="mdi-account"  
                    />
                    <v-text-field 
                        type="password"  
                        label="ລະຫັດຜ່ານ" 
                        placeholder="ລະຫັດຜ່ານ" 
                        outlined  
                        dense
                        v-model="formLogin.password" 
                        :rules="formLogin.passwordRules" 
                        required 
                        append-icon="mdi-lock" 
                         
                    />
                    <v-btn
                        type="submit"
                        color="amber lighten-1"
                        block
                        dense
                        :loading="$store.state.auth.loading2"
                    >ເຂົ້າສູ່ລະບົບ</v-btn>
                </v-form>
            </div>
        </div>
    </div>
</template>

<script>
    import md5 from 'md5';
    export default {
        data(){
            return {
                title:'ລະບົບອັບໂຫລດເອກະສານໂອນເງີນດ່ວນ',
                valid: false,
                formLogin: {
                    username: 'expressadmin',
                    password: '123456',
                    usernameRules: [
                        v => !!v || 'ກະລຸນາປ້ອນຂໍ້ມູນກ່ອນ',
                    ],
                    passwordRules: [
                        v => !!v || 'ກະລຸນາປ້ອນຂໍ້ມູນກ່ອນ',
                    ],
                },
                btnloading: false,
                alert: false,
                message: 'ຊື່ຜູ້ໃຊ້ ຫຼື ລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ',
            }
        },
        methods:{
            async submit () {
                if(this.valid){
                    const formdata = {
                        username: this.formLogin.username,
                        password: md5(this.formLogin.password)
                    }
                    await this.$store.commit("auth/setLoading2", true);
                    const result = await this.$store.dispatch("auth/getAuthLogin",formdata);
                    await this.$store.commit("auth/setLoading2", false);
                    if(result.success){
                        window.sessionStorage.removeItem('accessToken');
                        window.sessionStorage.removeItem('fullname');
                        window.sessionStorage.removeItem('brname');
                        window.sessionStorage.removeItem('changepassword');
                        window.sessionStorage.setItem('accessToken',result.data.accessToken);
                        window.sessionStorage.setItem('fullname',result.data.fullname);
                        window.sessionStorage.setItem('brname',result.data.brname);
                        window.sessionStorage.setItem('changepassword',result.data.changepassword);
                        this.$router.push({name: result.resRoutename});
                    }else{
                        this.alert = await true;
                        this.message = result.message;
                    }
                }else{
                    this.$refs.formdata.validate();
                    return false
                }
                
            },
        }
    }
</script>

<style scoped>
    .login-body {
        margin: 0;
        padding: 0;
        background: #f2f2f2;
        height: 100vh;
    }
    .login-box {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100vh;
        background-image: #f2f2f2;
        transition: 1s;
    }
    .login-form {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: black;
        text-align: center;
    }
    .login-form h1 {
        font-weight: 400;
        margin-top: 0;
    }
    .login-form h3{
        font-size: 1.3rem;
        font-weight: bold;
    }
    .login-btn {
        width: 100%;
        background: #007e33;
        border: 0;
        color: white;
        padding: 10px;
        border-radius: 6px;
        cursor: pointer;
    }
    .show-login-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: black;
        border: 2px solid;
        padding: 10px;
        cursor: pointer;
    }
    .showed {
        left: 0;
    }
</style>